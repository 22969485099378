// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IntroductionHeader_header__1JvA{gap:6px;color:#fff;font-size:17px;font-weight:700;line-height:normal;width:207px;margin-top:30px}.IntroductionHeader_header__1JvA,.IntroductionHeader_headerIcon_3XUOS{display:flex;align-items:center}.IntroductionHeader_headerIcon_3XUOS{justify-content:center;width:45px;height:45px;border-radius:50%;flex-shrink:0;flex-grow:0;border:2px solid #fff}", ""]);
// Exports
exports.locals = {
	"header": "IntroductionHeader_header__1JvA",
	"headerIcon": "IntroductionHeader_headerIcon_3XUOS"
};
module.exports = exports;
