// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IntroductionFourthStep_icon_1jlxS{color:#fff;fill:none;width:100%}", ""]);
// Exports
exports.locals = {
	"icon": "IntroductionFourthStep_icon_1jlxS"
};
module.exports = exports;
