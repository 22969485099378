// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IntroductionFirstStep_container_OiYqc{display:flex;flex-direction:column;justify-content:flex-start;position:relative}.IntroductionFirstStep_container_OiYqc .IntroductionFirstStep_robot_1PPfW{height:160px;margin-bottom:5px}.IntroductionFirstStep_container_OiYqc .IntroductionFirstStep_message_28mYL{border:2px solid var(--supster-primary-dark-background);background:var(--supster-primary-dark-background);width:230px;padding:7.31px;color:var(--supster-main-text-color);font-size:11.37px;font-weight:400;line-height:16.242px}.IntroductionFirstStep_container_OiYqc .IntroductionFirstStep_message_28mYL.IntroductionFirstStep_firstMessage_39jfT{border-radius:6px 0 6px 6px}.IntroductionFirstStep_container_OiYqc .IntroductionFirstStep_message_28mYL.IntroductionFirstStep_secondMessage_25val{margin-top:17.5px;width:245px;border-radius:0 6px 6px 6px;transform:translateX(-10%)}.IntroductionFirstStep_container_OiYqc .IntroductionFirstStep_post_2CnE5{position:absolute;right:13%;bottom:-26%}", ""]);
// Exports
exports.locals = {
	"container": "IntroductionFirstStep_container_OiYqc",
	"robot": "IntroductionFirstStep_robot_1PPfW",
	"message": "IntroductionFirstStep_message_28mYL",
	"firstMessage": "IntroductionFirstStep_firstMessage_39jfT",
	"secondMessage": "IntroductionFirstStep_secondMessage_25val",
	"post": "IntroductionFirstStep_post_2CnE5"
};
module.exports = exports;
