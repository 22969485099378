<template>
  <div :class="$style.container">
    <img src="@/assets/images/svg/robot.svg" alt="Robot" :class="$style.robot" />
    <div :class="[$style.message, $style.firstMessage]">
      {{ $t('gpt.introduction.message1') }}
    </div>
    <div :class="[$style.message, $style.secondMessage]">
      {{ $t('gpt.introduction.message2') }}
    </div>
    <img src="@/assets/images/post-example.png" alt="Post example" :class="$style.post" />
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'IntroductionFirstStep',
})
</script>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  .robot {
    height: 160px;
    margin-bottom: 5px;
  }

  .message {
    border: 2px solid $primary-dark-background;
    background: $primary-dark-background;
    width: 230px;
    padding: 7.31px;
    color: $main-text-color;
    font-size: 11.37px;
    font-weight: 400;
    line-height: 16.242px;

    &.firstMessage {
      border-radius: 6px 0 6px 6px;
    }
    &.secondMessage {
      margin-top: 17.5px;
      width: 245px;
      border-radius: 0 6px 6px 6px;
      transform: translateX(-10%);
    }
  }

  .post {
    position: absolute;
    right: 13%;
    bottom: -26%;
  }
}
</style>
