// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IntroductionFooter_footer_SdbAB{display:flex;flex-direction:column;gap:21px;align-items:center;text-align:center;width:100%}.IntroductionFooter_footer_SdbAB .IntroductionFooter_title_7x5M_{color:#a7bbf9;font-size:18px;font-weight:500;line-height:18px}.IntroductionFooter_footer_SdbAB .IntroductionFooter_text_1TAtz{font-size:12px;line-height:18px}.IntroductionFooter_footer_SdbAB .IntroductionFooter_navigation_3faLh{display:flex;justify-content:center;gap:6px}.IntroductionFooter_footer_SdbAB .IntroductionFooter_navigation_3faLh .IntroductionFooter_dot_2fRG_{border-radius:50%;width:9px;height:9px;border:1px solid #fff}.IntroductionFooter_footer_SdbAB .IntroductionFooter_navigation_3faLh .IntroductionFooter_dotActive_3MmPc{background:#fff}.IntroductionFooter_footer_SdbAB button{margin:9px 0}", ""]);
// Exports
exports.locals = {
	"footer": "IntroductionFooter_footer_SdbAB",
	"title": "IntroductionFooter_title_7x5M_",
	"text": "IntroductionFooter_text_1TAtz",
	"navigation": "IntroductionFooter_navigation_3faLh",
	"dot": "IntroductionFooter_dot_2fRG_",
	"dotActive": "IntroductionFooter_dotActive_3MmPc"
};
module.exports = exports;
