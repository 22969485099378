<template>
  <icon :class="$style.icon" name="bell" width="300" />
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'IntroductionSecondStep',
})
</script>

<style module lang="scss">
.icon {
  color: white;
  fill: none;
  transform: translateX(5%) translateY(5%);
}
</style>
