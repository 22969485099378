<template>
  <div :class="$style.footer">
    <h3 :class="$style.title">{{ title }}</h3>
    <p :class="$style.text">
      {{ text }}
    </p>
    <div :class="$style.navigation">
      <div :class="[$style.dot, i === step && $style.dotActive]" v-for="i in 4" :key="i" />
    </div>
    <VButton @click="onCLick" color="primary" size="xxl">{{ buttonText }} </VButton>
  </div>
</template>

<script>
import Vue from 'vue'
import VButton from '@elements/VButton.vue'

export default Vue.extend({
  name: 'IntroductionFooter',
  components: { VButton },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  computed: {
    text() {
      return this.$t(`gpt.introduction.page${this.step}.text`)
    },
    title() {
      return this.$t(`gpt.introduction.page${this.step}.title`)
    },
    buttonText() {
      return this.step === 4 ? this.$t('getStarted') : this.$t('next')
    },
  },
  methods: {
    onCLick() {
      if (this.step === 4) {
        this.$emit('finish')
        return
      }
      this.$emit('next')
    },
  },
})
</script>

<style lang="scss" module>
.footer {
  display: flex;
  flex-direction: column;
  gap: 21px;
  align-items: center;
  text-align: center;
  width: 100%;

  .title {
    color: #a7bbf9;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
  }

  .text {
    font-size: 12px;
    line-height: 18px;
  }

  .navigation {
    display: flex;
    justify-content: center;
    gap: 6px;

    .dot {
      border-radius: 50%;
      width: 9px;
      height: 9px;
      border: 1px solid white;

      &Active {
        background: white;
      }
    }
  }

  button {
    margin: 9px 0;
  }
}
</style>
