<template>
  <div :class="$style.container">
    <IntroductionBackground />
    <IntroductionHeader />
    <div :class="$style.content">
      <component :is="component" />
    </div>
    <IntroductionFooter :step="step" @next="next" @finish="finish" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import { isNavigationFailure, NavigationFailureType } from 'vue-router'
import VButton from '@elements/VButton.vue'
import IntroductionFirstStep from '@modules/introduction/IntroductionFirstStep.vue'
import IntroductionSecondStep from '@modules/introduction/IntroductionSecondStep.vue'
import IntroductionThirdStep from '@modules/introduction/IntroductionThirdStep.vue'
import IntroductionFourthStep from '@modules/introduction/IntroductionFourthStep.vue'
import IntroductionBackground from '@modules/introduction/IntroductionBackground.vue'
import IntroductionHeader from '@modules/introduction/IntroductionHeader.vue'
import IntroductionFooter from '@modules/introduction/IntroductionFooter.vue'

export default Vue.extend({
  name: 'Introduction',
  components: {
    IntroductionFooter,
    IntroductionHeader,
    IntroductionBackground,
    IntroductionFirstStep,
    IntroductionSecondStep,
    IntroductionThirdStep,
    IntroductionFourthStep,
    VButton,
  },
  computed: {
    ...mapGetters('Locale', ['locale']),
    step() {
      return Number(this.$route.params?.step) || 1
    },
    componentList() {
      return ['First', 'Second', 'Third', 'Fourth']
    },
    component() {
      const numeral = this.componentList[this.step - 1] ?? null
      return numeral && `Introduction${numeral}Step`
    },
  },
  methods: {
    ...mapMutations('Page', ['setPageIsFooter', 'setPageIsHeader', 'setIsFirstLaunch']),
    next() {
      const step = this.step + 1 > 4 ? 1 : this.step + 1
      this.$router.push({ name: 'Introduction', params: { step, locale: this.locale } })
    },
    finish() {
      this.setPageIsHeader(true)
      this.setIsFirstLaunch(false)
      this.$router.push({ path: `/${this.locale}/` }).catch((error) => {
        if (!isNavigationFailure(error, NavigationFailureType.redirected)) {
          Promise.reject(error)
        }
      })
    },
  },
  created() {
    this.setPageIsFooter(false)
    this.setPageIsHeader(false)
  },
})
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: white;
  padding: 15px;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(8, 5, 26, 0.6) 0%, rgba(8, 5, 26, 0.6) 100%),
    linear-gradient(166deg, #11ac86 0%, #08051a 100%, #08051a 100%);

  .content {
    height: 330px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
