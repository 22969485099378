<template>
  <icon :class="$style.icon" name="introduction-compilation" />
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'IntroductionThirdStep',
})
</script>

<style module lang="scss">
.icon {
  color: white;
  fill: none;
  width: 100%;
}
</style>
