<template>
  <div :class="$style.background">
    <icon name="rings-background" />
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'IntroductionBackground',
})
</script>

<style lang="scss" module>
.background {
  position: absolute;
  top: 15%;
  height: 70%;
  width: 100%;

  svg {
    fill: none;
  }
}
</style>
