// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IntroductionSecondStep_icon_1Eu8_{color:#fff;fill:none;transform:translateX(5%) translateY(5%)}", ""]);
// Exports
exports.locals = {
	"icon": "IntroductionSecondStep_icon_1Eu8_"
};
module.exports = exports;
