// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Introduction_container_eU7mj{flex-direction:column;height:100%;color:#fff;padding:15px;justify-content:space-between;background:linear-gradient(0deg,rgba(8,5,26,.6),rgba(8,5,26,.6)),linear-gradient(166deg,#11ac86,#08051a 100%,#08051a 0)}.Introduction_container_eU7mj,.Introduction_container_eU7mj .Introduction_content_10ODe{display:flex;align-items:center}.Introduction_container_eU7mj .Introduction_content_10ODe{height:330px;width:100%;justify-content:center}", ""]);
// Exports
exports.locals = {
	"container": "Introduction_container_eU7mj",
	"content": "Introduction_content_10ODe"
};
module.exports = exports;
