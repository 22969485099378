// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IntroductionBackground_background_2ARKr{position:absolute;top:15%;height:70%;width:100%}.IntroductionBackground_background_2ARKr svg{fill:none}", ""]);
// Exports
exports.locals = {
	"background": "IntroductionBackground_background_2ARKr"
};
module.exports = exports;
