<template>
  <div name="logo" :class="$style.header">
    <div :class="$style.headerIcon">
      <icon name="openai" color="white" width="27" height="27" />
    </div>
    {{ $t('gpt.introduction.headerText') }}
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'IntroductionHeader',
})
</script>

<style lang="scss" module>
.header {
  display: flex;
  gap: 6px;
  color: white;
  align-items: center;
  font-size: 17px;
  font-weight: 700;
  line-height: normal;
  width: 207px;
  margin-top: 30px;

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid white;
  }
}
</style>
